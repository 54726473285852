.btn-close{
	background: none;
	color: #fff;
	padding: 0;
    height: auto;
    width: auto;
}

// Modal Box
.inquiry-modal{
	padding: 0 15px;
	.modal-dialog{
		max-width: 700px;
		display: flex;
		min-height: auto;
		background-color: #fff;
		align-items: stretch;
		border-radius: var(--border-radius-base);
		overflow: hidden;
	}
	.inquiry-adv{
		flex: 0 0 50%;
		max-width:50%;
		
		img{
			height:100%;
			object-fit:cover;
			width:100%;
		}
	}
	.modal-content{
		border: 0;
		border-radius: 0;
		background: #fff;
		padding: 30px;
		flex: 0 0 50%;
		max-width:50%;
		@include respond('phone-land'){
			flex: 0 0 100%;
			max-width:100%;
			padding: 20px;
		}
		.modal-header{
			border:0;
			padding:0;
			display: block;
			text-align: center;
			
			.modal-title{
				font-size: 24px;
				margin-bottom: 0;
				line-height: 1.3;
				font-family: var(--font-family-title);
				text-align: center;
				width: 100%;
			}
			i{
				color: var(--primary);
				font-size: 54px;
				line-height: 1;
				display: inline-block;	
			}
		}
		.modal-body{
			padding:0;
		}
		@include respond('phone-land'){
			.modal-header{
				.modal-title {
					font-size: 18px;
					padding: 5px 15px 15px;
				}
				i{
					font-size: 36px;
				}
			}
		}
	}
	.btn-close {
		margin: 0;
		position: absolute;
		right: 5px;
		top: 0px;
		color: #000;
		font-weight: 100;
		text-shadow: none;
		opacity: 1;
		font-size: 40px;
		padding: 0;
		height: 40px;
		line-height: 40px;
		width: 40px;
		border: 0;
		background: transparent;
	}
}
.modal.modal-wrapper-lg{
	.modal-dialog {
		max-width: 600px;
	}
}

.input-wrapper {
	position: relative;
}

.input-wrapper .suffix {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
  }  

.modal-wrapper{
	.modal-header{
		padding: 15px 30px;
		background-color: var(--primary);
		border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
		
		.modal-title{
			color: #fff;
		}
		@include respond('phone'){
			padding: 15px 25px;			
		}
	}
	.modal-body{
		padding: 30px;
		@include respond('phone'){
			padding: 25px;
		}
	}
	.modal-title{
		padding: 0;
		@include respond('phone'){
			font-size: 18px;
		}
	}
	.modal-content {
		border-radius: var(--border-radius-base);
		border: 0;
	}
	.modal-content + .modal-content{
		margin:0;
	}
	.modal-dialog {
		max-width: 1050px;
		position: relative;
		padding: 15px 15px;
	}
	.form-group{
		margin-bottom: 20px;
		text-align: left;
	}
}
.auth-modal{
	.modal-dialog{
		padding: 0;
		max-width: 380px;
	}
	.btn{
		font-size: 14px;
	}
	.modal-content{
		padding: 30px;
	}
	.title{
		font-size: 20px;
		font-weight: 500;
		text-align: center;
		margin: -30px -30px 30px;
		background: var(--primary);
		line-height: 1.2;
		padding: 18px 20px;
		color: #fff;
		border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
	}
	.sign-text{
		background: #eee;
		margin: 30px -30px -30px -30px;
		padding: 10px 30px;
		font-size: 13px;
		text-align: center;
		font-weight: 500;
		color: #000;
		border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
		
		.btn-link {
			font-size: 13px;
		}
	}
	.reset-password{
		text-align:right;
	}
	.reset-password .btn-link{
		font-size: 14px;
	}	
	.reset-password.modal-content{
		position: absolute;
		top: 0;
		z-index: 1;
		height: 100%;
		left: 0;
		
		.sign-text {
			margin: 0;
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
		}
	}
	.reset-password.modal-content .sign-text{
		margin: 0;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
	}
	.password-icon-bx {
		p {
			text-align:center;	
			font-size: 14px;
			font-weight: 500;
		}
		i {
			width: 100px;
			height: 100px;
			margin: 0px auto 20px;
			display: block;
			text-align: center;
			line-height: 100px;
			font-size: 36px;
			background: #eee;
			border-radius: 100%;
		}
	}
	
	.sign-up.modal-content {
		position: absolute;
		top: 0;
		z-index: 1;
		height: 100%;
		.sign-text {
			margin: 0;
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
		}
	}
	.modal-content + .modal-content{
		margin: 0;
	}
	.small-bx{
		background: #f6f7f7;
		display: block;
		padding: 8px 10px;
		margin: 0 -30px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}
}


.modal-title{
    font-size: 20px;
    padding: 20px 30px;
    font-weight: 500;
}
.popup-profile-info{
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
}
.popup-profile-info li{
	flex: 0 0 50%;
    width: 50%;
    padding: 5px 0;
	font-size: 15px;
}
.popup-profile-info li strong{
	margin-right: 10px;
    font-weight: 600;
    color: #000;
}
.popup-profile-info li span{
	color: #929292;
	font-weight: 400;
}

